<script setup>
const {$activeModalsBus} = useNuxtApp();
const show = ref(false);
const product = ref(null);
$activeModalsBus.listen("openUpsaleProductPop", (productData) => {
  show.value = true;
  product.value = productData;
});
$activeModalsBus.listen("closeUpsaleProductPop", () => {
  show.value = false;
});
</script>
<template>
  <VDialog v-model="show" max-width="1000" scrim scroll-strategy="block" class="product-info-pop" location-strategy="connected">
    <v-card class="modal-content p-3">
      <a class="modal-close modal-close--absolute no_mobile" @click="$activeModalsBus.event('closeUpsaleProductPop')">
        <img
            src="https://admin.sasa.co.il/wp-content/themes/sasa/assets/images/close.svg"
            alt="אייקון של X לסימון סגירה חלון באתר"
        />
      </a>
      <div class="modal-body p-3">
        <div class="d-flex flex-column flex-md-row">
          <div class="col-md-6 pl-2 .product-gallery-wrap">
            <GallerySwiper :product="product"/>
          </div>

          <div class="col-md-6">
            <h4 class="text-center">{{ product?.title }}</h4>
            <div class="text" v-html="product?.shortDescription"></div>
            <div class="text-center my-3">
              <a
                  class="modal-close"
                  style="color: red"
                  href="javascript:void(0)"
                  @click="$activeModalsBus.event('closeUpsaleProductPop')"
              >
                סגור
              </a>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </VDialog>
</template>
<style lang="scss">
.product-info-pop{

  .swiper-button-prev:after,
  .product-gallery-wrap .swiper-button-next:after {
    content: initial;
  }
  .swiper-button-next,
  .swiper-button-prev{
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .arr{
    @media screen and (max-width: 800px) {
      max-width: 20px;
    }
  }
}
</style>
